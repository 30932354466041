import React from 'react';
import PropTypes from 'prop-types';
import { SvgLoader } from 'react-svgmt';

import ArrowDownSVG from '../icons/arrow-down.svg';
import ArrowLeftSVG from '../icons/arrow-left.svg';
import ArrowRightSVG from '../icons/arrow-right.svg';
import CloseSVG from '../icons/close.svg';
import LogoSVG from '../icons/logo.svg';

import styles from './icons.module.scss';

export const ArrowDownIcon = (props) => (
  <Icon icon={ArrowDownSVG} {...props}/>
);

export const ArrowLeftIcon = (props) => (
  <Icon icon={ArrowLeftSVG} {...props}/>
);

export const ArrowRightIcon = (props) => (
  <Icon icon={ArrowRightSVG} {...props}/>
);

export const CloseIcon = (props) => (
  <Icon icon={CloseSVG} {...props}/>
);

export const LogoIcon = (props) => (
  <Icon icon={LogoSVG} {...props}/>
);

export const MenuIcon = ({ isActive, className }) => (
	<svg
		viewBox='0 0 40 40'
		className={`
			${styles.menu}
			${className ? ` ${className}` : ''}
			${isActive ? ` ${styles.menuActive}` : ''}
		`}>
		<g className={styles.menuLines}>
			<line
				x1='0'
				x2='40'
				y1='5'
				y2='5'
				className={styles.menuLine1}>
			</line>
			<line
				x1='0'
				x2='40' 
				y1='20'
				y2='20'
				className={styles.menuLine2}>
			</line>
			<line
				x1='0'
				x2='40' 
				y1='20'
				y2='20'
				className={styles.menuLine3}>
			</line>
			<line
				x1='0'
				x2='40'
				y1='35'
				y2='35' 
				className={styles.menuLine4}>
			</line>
		</g>
	</svg>
);

MenuIcon.propTypes = {
	isActive: PropTypes.bool.isRequired,
	className: PropTypes.string
};

export const SVG = (props) => {
	const {
		path, 
		className = ''
	} = props;
	props = {
		...props,
		...{
			className: `${styles.icon} ${className}`
		}
	}

	delete props.path;

	return (
		<SvgLoader 
			path={path} 
			{...props} />
 	);
};

SVG.defaultProps = {
	path: null,
	className: ''
};

SVG.propTypes = {
	path: PropTypes.string.isRequired,
	className: PropTypes.string
};

const Icon = (props) => {
	const { icon: { id, viewBox }, className = '' } = props;
	props = {
		...props,
		...{
			className: `${styles.icon} ${className}`
		}
	}

	delete props.icon;

	return (
		<svg viewBox={viewBox} {...props}>
	    	<use xlinkHref={`#${id}`} />
	  	</svg>
	);
};

Icon.propTypes = {
	icon: PropTypes.shape({
		id: PropTypes.string.isRequired,
		content: PropTypes.string.isRequired,
		viewBox: PropTypes.string.isRequired
	}).isRequired,
	className: PropTypes.string
};

export default Icon;
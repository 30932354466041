
import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

import { Conditional } from './utils';
import { MenuIcon, LogoIcon } from './icons';
import LocaleContext from '../contexts/locale';
import { usePagesByMenu, MENU_TYPE_MAIN } from '../hooks/use-pages';

import styles from './header.module.scss';

//
// HEADER
//
const Header = ({ showMenu, isHidden, isFixed, onToggle }) => (
	<header
		className={[
			styles.header,
			(isFixed && styles.headerFixed) || '',
			(isHidden && styles.headerHidden) || ''
		]
			.filter(Boolean)
			.join(' ')}>
		<div className={styles.headerContainer}>
			<Menu isOpen={showMenu} onToggle={onToggle} />
			<Logo isSmall={isFixed} />
			<Languages />

			{showMenu && (
				<Helmet>
					<body className={styles.bodyMenuOpen} />
				</Helmet>
			)}
		</div>
	</header>
);

Header.defaultProps = {
	isFixed: false,
	showMenu: false,
	isHidden: false,
	onToggle: () => {}
};

Header.propTypes = {
	isFixed: PropTypes.bool.isRequired,
	showMenu: PropTypes.bool.isRequired,
	onToggle: PropTypes.func.isRequired,
	isHidden: PropTypes.bool.isRequired
};

export default Header;

//
// LOGO
//
const Logo = () => {
	const { currentLocale, defaultLocale } = useContext(LocaleContext);

	return (
		<div className={styles.logo}>
			<Link
				className={styles.logoLink}
				to={currentLocale !== defaultLocale ? `/${currentLocale}` : '/'}>
				<LogoIcon className={styles.logoSvg} />
			</Link>
		</div>
	);
};

Logo.defaultProps = {
	isSmall: false
};

Logo.propTypes = {
	isSmall: PropTypes.bool
};

//
// MENU
//
const Menu = ({ isOpen, onToggle }) => (
	<Fragment>
		<button onClick={onToggle} className={styles.menuToggle}>
			<MenuIcon isActive={isOpen} className={styles.menuToggleSvg} />
		</button>
		<Navigation isHidden={!isOpen} />
	</Fragment>
);

Menu.defaultProps = {
	isOpen: false,
	onToggle: () => {}
};

Menu.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onToggle: PropTypes.func.isRequired
};

//
// NAVIGATION
//

const navUrlToTtile = ['/kontakt','/team','/en/team','/en/contact'];
const Navigation = ({ isHidden }) => (
	<nav className={!isHidden ? `${styles.nav}` : `${styles.nav} ${styles.navHidden}`}>
		<div className={styles.navOuter}>
			<div className={styles.navInner}>
				{usePagesByMenu(MENU_TYPE_MAIN).map(({ slug, title, key }, index) => {
					return (
						<Link
							to={slug}
							key={index}
							partiallyActive={true}
							className={`${styles.navItem}`}
							activeClassName={styles.navItemActive}>
							{navUrlToTtile.includes(slug)? slug.replace("/","").replace('en/',""): title}
						</Link>
					);
				})}
			</div>
		</div>
	</nav>
);

Navigation.defaultProps = {
	isHidden: false
};

Navigation.propTypes = {
	isHidden: PropTypes.bool.isRequired
};

//
// LANGUAGES
//
const Languages = () => {
	const { locales, currentLocale, defaultLocale, setCurrentLocale } = useContext(
		LocaleContext
	);

	const onClick = ({ target: { hreflang: locale } }) => setCurrentLocale(locale);

	return (
		<nav className={styles.languages}>
			{locales.map((locale, index) => (
				<div key={index} className={styles.languageItem}>
					<Conditional
						condition={locale !== currentLocale}
						wrapper={children => (
							<div className={styles.languageItemLink}>
								<Link
									rel="alternate"
									hrefLang={locale}
									onClick={onClick}
									className={styles.languageItemLink}
									to={locale !== defaultLocale ? `/${locale}` : '/'}>
									{children}
								</Link>
							</div>
						)}
						fallbackWrapper={children => (
							<div
								className={`${styles.languageItemLink} ${styles.languageItemLinkActive}`}>
								{children}
							</div>
						)}>
						<span>{locale}</span>
					</Conditional>

					<span className={styles.languageItemSeparator} />
				</div>
			))}
		</nav>
	);
};

import { parsePhoneNumberFromString, parseDigits } from 'libphonenumber-js';

//
// Parses and formats a phone number as international one
//
export const format = string => {
	try {
		const number = parsePhoneNumberFromString(string);
		return number.formatInternational();
	} catch (e) {
		return '-';
	}
};

//
// Parses and formats a for `href` usage with `tel:` protocol
//
export const toUri = string => {
	try {
		const number = parsePhoneNumberFromString(string);
		return number.getURI();
	} catch (e) {
		return null;
	}
};

//
// Parses and formats a for `href` usage with Click-To-Chat:
// https://faq.whatsapp.com/de/android/26000030/
//
export const toClickToChat = string => {
	try {
		const number = parseDigits(string);
		return `https://wa.me/${number}`;
	} catch (e) {
		return null;
	}
};

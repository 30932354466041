import React from 'react';
import { Helmet } from 'react-helmet';

import { isProduction } from '../utils/env';
import useSiteMeta from '../hooks/use-site-meta';

// import styles from './google-analytics.module.scss';

//
// TRACK PAGE VIEW FUNCTION
//
export const trackPageView = () => {
	// requires analytics to be loaded
	// @see: GoogleAnalytics component
	if (typeof gtrack === 'undefined') {
		return;
	}

	// tracks generic page view
	// eslint-disable-next-line
	gtrack();
};

//
// TRACK CUSTOM EVENT FUNCTION
//
export const trackEvent = (...args) => {
	// requires analytics to be loaded
	// @see: GoogleAnalytics component
	if (typeof gevent === 'undefined') {
		return;
	}

	// tracks a custom event
	// eslint-disable-next-line
	gevent(...args);
};

//
// TRACK ADWORDS CONVERSION FUNCTION
//
export const trackConversion = (...args) => {
	// requires analytics to be loaded
	// @see: GoogleAnalytics component
	if (typeof gevent === 'undefined') {
		return;
	}

	// tracks an adwords conversion
	// eslint-disable-next-line
	gconversion(...args);
};

//
// GOOGLE ANALYTICS
//
const GoogleAnalytics = () => {
	const {
		gaAnonymizeIp,
		gaTrackingId,
		awConversionId,
		awConversionLabel,
		hostName
	} = useSiteMeta();

	return (
		<Helmet>
			<script
				async
				key="ga-base-script"
				src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
			/>
			<script key="ga-setup-script">{`
				window.dataTrack = ${isProduction};
				window.dataLayer = window.dataLayer || [];
				function gtag(){dataLayer.push(arguments);}
				function gtrack() {
					var pagePath = location.pathname + location.search + location.hash;
					if (window.dataTrack && location.hostname.indexOf('${hostName}') >= 0) {
						gtag('config', '${gaTrackingId}', {
							anonymize_ip: ${gaAnonymizeIp},
							page_path: pagePath
						});
						gtag('config', '${awConversionId}', {
							conversion_linker: false,
							send_page_view: false
						});
					}
				}
				function gevent(action, category, label, value, callback) {
					if (dataTrack) {
						gtag('event', action, {
							event_callback: callback || function(){},
							event_category: category || '',
							event_label: label || '',
							value: value || ''
						});
					} else {
						if (typeof callback === 'function') {
							callback();
						}
					}
				}
				function gconversion(value, currency, callback) {
					if (dataTrack) {
						gtag('event', 'conversion', {
							send_to: '${awConversionId}/${awConversionLabel}',
							event_callback: callback || function(){},
							currency: currency || 'EUR',
							value: value || 1.0
						});
					} else {
						if (typeof callback === 'function') {
							callback();
						}
					}
				}
				gtag('js', new Date());
				gtrack();
			`}</script>
		</Helmet>
	);
};

export default GoogleAnalytics;

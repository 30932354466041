import React, { createContext, useState, useEffect } from 'react';

import { getScrollOffsetY } from '../utils/dom';

export const ScrollProvider = ({ children, slug }) => {
	const [isScrollingDown, setIsScrollingDown] = useState(false);
	const [isScrollingUp, setIsScrollingUp] = useState(false);
	const [scrollY, setScrollY] = useState(0);

	let prevScrollY = getScrollOffsetY();
	let foreLastScrollYPos = prevScrollY;
	let recursionDetected = false;
	let isTicking = false;

	useEffect(() => {
		const handleScroll = (/* event */) => {
			if (recursionDetected !== false) {
				return;
			}

			// note: for delta scroll pos should be larger 0 to avoid taking browser bouncing effects
			// into account such as the one used in iOS when scrolling up at the top of the page!
			const lastScrollY = getScrollOffsetY();
			const scrollDelta = lastScrollY > 0 ? lastScrollY - prevScrollY : 0;

			if (!isTicking) {
				requestAnimationFrame(() => {
					// note: this happens if setting a fixed header gets follwed by a disappearing
					// scrollbar as page gets too small, but this would force an infinite loop in
					// browser, as the scroll position then jumps to 0 - stop updates in this case
					recursionDetected = lastScrollY === 0 && foreLastScrollYPos === 0;
					foreLastScrollYPos = prevScrollY;
					prevScrollY = lastScrollY;

					setIsScrollingDown(scrollDelta > 0);
					setIsScrollingUp(scrollDelta < 0);
					setScrollY(lastScrollY);

					isTicking = false;
				});

				isTicking = true;
			}
		};

		document.addEventListener('scroll', handleScroll, false);

		return () => {
			document.removeEventListener('scroll', handleScroll, false);
		};
	}, [slug]);

	useEffect(() => {
		const handleResize = (/* event */) => {
			recursionDetected = false;
		};

		document.addEventListener('resize', handleResize, false);

		return () => {
			document.removeEventListener('resize', handleResize, false);
		};
	}, []);

	const value = {
		isScrollingDown,
		isScrollingUp,
		scrollY
	};

	return <ScrollContext.Provider value={value}>{children}</ScrollContext.Provider>;
};

const ScrollContext = createContext();

export default ScrollContext;

import { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby';

import LocaleContext from '../contexts/locale';

const useTranslations = (key) => {
  const {
    currentLocale
  } = useContext(LocaleContext);

  const {
    allFile: {
      edges: languages
    }
  } = useStaticQuery(query);

  const translations = {};

  const language = languages.find(({ node }) => {
      return node.name === currentLocale;
  });

  if (language === undefined) {
    return translations;
  }

  const {
    node: {
      json
    }
  } = language;

  if (typeof key === 'string') {
    return json[key] || key;
  }

  return json;
};

export default useTranslations;

const query = graphql`
  query useTranslations {
    allFile(filter: { sourceInstanceName: { eq: "translations" } }) {
      edges {
        node {
          name
          json: childTranslationsJson {
            ...TranslationsFragment
          }
        }
      }
    }
  }
`;
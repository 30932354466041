import React from 'react';

import Layout from './src/components/layout';
import { LocaleProvider } from './src/contexts/locale';
import { ScrollProvider } from './src/contexts/scroll';
import { MetaProvider } from './src/contexts/meta';

const wrapPageElement = ({ element, props }) => {
	const {
		pageContext: { locale, slug }
	} = props;

	return (
		<LocaleProvider locale={locale}>
			<ScrollProvider slug={slug}>
				<MetaProvider slug={slug}>
					<Layout {...props}>{element}</Layout>
				</MetaProvider>
			</ScrollProvider>
		</LocaleProvider>
	);
};

export default wrapPageElement;

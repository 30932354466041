import { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import LocaleContext from '../contexts/locale';

const all = [];
const map = {};

const useBlockGeneral = () => {
	const {
		allMarkdownRemark: {
			edges: blocks
		}
	} = useStaticQuery(query);

	const {
		currentLocale
	} = useContext(LocaleContext);

	const mapItem = ({
		node: {
			id,
			fields,
			frontmatter: data
			
		}
	}) => ({
		...data,
		...fields,
		...{
			id
		}
	});
	
	if (all.length === 0) {
		for (let block of blocks) {
			const item = mapItem(block);
			const { locale } = item;

			map[locale] = item;
			all.push(item);
		}
	}

	return map[currentLocale];
}

export default useBlockGeneral;

const query = graphql`
	query useBlockGeneralQuery {
		allMarkdownRemark(
	    	filter: {
		        frontmatter: {
		          type: {
		            eq: "block"
		          }
		          name: {
		          	eq: "general"
		          }
		        }
		  	}
	  	)
	  	{
			edges {
				node {
					id
					frontmatter {
						company_name
						phone_prefix
						email_prefix
					}
					fields {
						locale
					}
				}
			}
		}
	}
`;
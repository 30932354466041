// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../src/templates/contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-downloads-jsx": () => import("./../src/templates/downloads.jsx" /* webpackChunkName: "component---src-templates-downloads-jsx" */),
  "component---src-templates-imprint-jsx": () => import("./../src/templates/imprint.jsx" /* webpackChunkName: "component---src-templates-imprint-jsx" */),
  "component---src-templates-index-jsx": () => import("./../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-offer-jsx": () => import("./../src/templates/offer.jsx" /* webpackChunkName: "component---src-templates-offer-jsx" */),
  "component---src-templates-offers-jsx": () => import("./../src/templates/offers.jsx" /* webpackChunkName: "component---src-templates-offers-jsx" */),
  "component---src-templates-privacy-jsx": () => import("./../src/templates/privacy.jsx" /* webpackChunkName: "component---src-templates-privacy-jsx" */),
  "component---src-templates-service-jsx": () => import("./../src/templates/service.jsx" /* webpackChunkName: "component---src-templates-service-jsx" */),
  "component---src-templates-services-jsx": () => import("./../src/templates/services.jsx" /* webpackChunkName: "component---src-templates-services-jsx" */),
  "component---src-templates-team-jsx": () => import("./../src/templates/team.jsx" /* webpackChunkName: "component---src-templates-team-jsx" */),
  "component---src-templates-terms-jsx": () => import("./../src/templates/terms.jsx" /* webpackChunkName: "component---src-templates-terms-jsx" */)
}


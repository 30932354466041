import React, { Fragment, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { HTML } from '../components/utils';

import ScrollContext from '../contexts/scroll';
import LocaleContext from '../contexts/locale';
import MetaTags from '../components/meta-tags';
import Contact from '../components/contact';

import Footer from './footer';
import Header from './header';
import styles from './layout.module.scss';

//
// LAYOUT
//
const Layout = ({ children, pageContext: { slug, locale } }) => {
	const { isScrollingDown, scrollY } = useContext(ScrollContext);
	const { setCurrentLocale } = useContext(LocaleContext);
	const [hideHeader, setHideHeader] = useState(false);
	const [showMenu, setShowMenu] = useState(false);
	const [isFixed, setIsFixed] = useState(false);

	const onToggle = () => setShowMenu(!showMenu);

	useEffect(() => {
		if (showMenu) {
			const handleKeyDown = ({ keyCode }) => {
				switch (keyCode) {
					case 27:
						setShowMenu(false);
						break;
					default:
						break;
				}
			};

			window.addEventListener('keydown', handleKeyDown, false);

			return () => {
				window.removeEventListener('keydown', handleKeyDown, false);
			};
		}
	}, [showMenu]);

	useEffect(() => {
		// note: this is to catch users navigating between
		// different locales using the browser back button
		setCurrentLocale(locale);
	}, [locale]);

	useEffect(() => {
		// automatically hide menu when changing pages
		setShowMenu(false);
	}, [slug]);

	useEffect(() => {
		// hide header as soon as we are scrolling down
		setHideHeader(isScrollingDown);

		// set header fixed as soon as we are scrolling
		setIsFixed(scrollY > 0);
	});

	return (
		<div className={styles.wrapper}>
			<Header
				isFixed={isFixed}
				onToggle={onToggle}
				showMenu={showMenu}
				isHidden={hideHeader}
			/>
			<main className={styles.main}>{children}</main>

			<Footer />
			<MetaTags />
		</div>
	);
};

export default Layout;

//
// PAGE
//
export const Page = ({ slug, body, teaser, children, showContact, extraFooter }) => (
	<Fragment>
		<div className={styles.page}>
			{teaser}
			{body && (
				<div id="body" className={styles.header}>
					{body && <HTML content={body} />}
				</div>
			)}
			<div className={styles.body}>{children}</div>
		</div>
		{showContact && (
			<div className={styles.contact}>
				<Contact />
			</div>
		)}
		<div className={styles.extraFooter}>
			{extraFooter}
		</div>
	</Fragment>
);

Page.defaultProps = {
	slug: null,
	body: null,
	teaser: null,
	showContact: true,
	extraFooter: null
};

Page.propTypes = {
	slug: PropTypes.string,
	body: PropTypes.string,
	teaser: PropTypes.node,
	showContact: PropTypes.bool,
	extraFooter: PropTypes.node
};

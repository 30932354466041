//
// Checks if given string is a valid email (simple check only)
// 
export const isValid = (string) => {
	return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(string); 
};

//
// Formats email for `href` usage with `mail:` protocol if valid
// 
export const toUri = (string) => {
	return isValid(string) ? `mailto:${string}` : null;
};


import React from 'react';
import { Link } from 'gatsby';

import { SVG } from './icons';
import { Conditional } from './utils';
import { trackEvent } from './google-analytics';
import { toUri as toPhoneUri } from '../utils/phone';
import { toUri as toEmailUri } from '../utils/email';
import useBlockFooter from '../hooks/use-block-footer';
import { usePagesByMenu, MENU_TYPE_MISC } from '../hooks/use-pages';

import styles from './footer.module.scss';

//
// FOOTER
//
const Footer = () => {
	const { icons, lines, copyright } = useBlockFooter();
	const year = new Date().getFullYear();

	return (
		<footer className={styles.wrapper}>
			<div className={styles.container}>
				<div className={styles.top}>
					<div className={styles.icons}>
						{Array.isArray(icons) &&
							icons.map(({ icon: { publicURL: src }, link }, index) => (
								<div key={index} className={styles.icon}>
									<Conditional
										condition={link}
										wrapper={children => (
											<a href={link} target="_blank" className={styles.iconLink}>
												{children}
											</a>
										)}>
										<SVG path={src} className={styles.iconSvg} />
									</Conditional>
								</div>
							))}
					</div>

					<div className={styles.menu}>
						{usePagesByMenu(MENU_TYPE_MISC).map(({ slug, title }) => (
							<div key={slug} className={styles.item}>
								<Link
									to={slug}
									className={styles.itemLink}
									activeClassName={styles.itemLinkActive}>
									{title}
								</Link>
								<span className={styles.itemLinkSeparator} />
							</div>
						))}
					</div>
				</div>

				<div className={styles.bottom}>
					<div key="copyright" className={styles.copyright}>
						&copy; {year} {copyright}
					</div>

					{Array.isArray(lines) &&
						lines.map((line, index) => {
							const link = toEmailUri(line) || toPhoneUri(line);
							const onClick = ({ currentTarget: { href } }) => {
								switch (true) {
									case href.indexOf('mailto:') === 0:
										trackEvent('mail', 'mailfooter', undefined, 10);
										break;
									case href.indexOf('tel:') === 0:
										trackEvent('phone', 'phonefooter', undefined, 10);
										break;
									default:
								}
							};

							return (
								<div key={index} className={styles.line}>
									<Conditional
										condition={link}
										wrapper={children => (
											<a href={link} onClick={onClick} className={styles.lineLink}>
												{children}
											</a>
										)}>
										<span className={styles.lineText}>{line}</span>
									</Conditional>
								</div>
							);
						})}
				</div>
			</div>
		</footer>
	);
};

export default Footer;
